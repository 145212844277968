import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider, connect } from "react-redux";
import { legacy_createStore as createStore} from 'redux';
import reducer from "./DataStore";
import './App.css';
import App from './app';
import reportWebVitals from './reportWebVitals';
const dataStore = createStore(reducer);
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
    <Provider store={dataStore}>
      <App />
    </Provider>
  // </React.StrictMode>
);

// ReactDOM.render(
//   <Provider  store={dataStore}>
//   <App />
// </Provider>
// , document.getElementById('root')
// )

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
