

import React, { lazy, Suspense } from 'react';
import { createBrowserHistory } from "history";
import $ from 'jquery';
import LazyLoad, { forceCheck, forceVisible } from 'react-lazyload';
import { Link, Route, Switch } from "react-router-dom";
import LoadingPlaceHolder from "./RLoadingPlaceHolder";
import MLayout from "./MLayout";
import ErrorBoundary from "./ErrorBoundary";
import {SetCurrentComponentName, callIOSImageClose, makeUrlSchemeCallforIOS, OpenTheUrlinWeb } from "./RUtility";
const AutismGPT=lazy(() => import('./AutismGPT'));

let options = {
    root: null, // document.querySelector('#articlepage-div-load-wall'),
    rootMargin: '0px',
    threshold: 1
}

let observer = new IntersectionObserver(function (entries) {
    entries.map((entry) => {
        //console.log("CAME Inside")
        // console.log(entry.target.id)
        if (entry.isIntersecting) {
            try {

                forceCheck();

            } catch (e) {
                console.log("WE ARE FFFF");
                console.log(e);
            }
        }
    });
}, options);

let searchPage = null;
var headerClassName = "";
export default class OverlayComponenet extends React.Component {

    constructor(props) {
        super(props);
        try{
            SetCurrentComponentName("SecondOverlayComponenet");
        }
        catch{
        }
        this.filterstate = { IsUserAdmin: false, IsUserLabTech: false, IsUserPhleby: false, IsUserWellnessExpert: false, CurrentTab: "Home", IsHome: true, IsMedical: false, IsWellness: false, IsUpdates: false, IsMore: false, SearchResult: "" }
        
        console.log(this.props.location);

        console.log("===== OverLay Component Loaded ======");
        $('html, body').addClass('popUpBackround');

        try {
          //  console.log("Object.entries(window.headerColorPallet)");
            headerClassName = window.headerColorPallet[Object.keys(window.headerColorPallet).filter(function (k) { return  window.location.href.toLowerCase().indexOf('allcompensations') <= -1 &&  window.location.href.toLowerCase().indexOf("/"+k) > -1 })[0]];

            if (window.publishType && window.publishType == "award") {
                headerClassName += " goldenC"
            }
            if (window.publishType && window.publishType == "memo") {
                headerClassName += " goldenb"
            }
        } catch (e) {
            console.log(e);
        }
    }

    back() {

        $('html, body').removeClass('popUpBackround');
        if (window.historyInstance.length <= 1) {
            window.historyInstance.push("/d");
        }
        else {
            window.historyInstance.goBack();
        }

        callIOSImageClose()
        //SocketSync(0, function (data) {
        //});
    }

    handleChange(e) {
        setTimeout(function () {
            if (searchPage) {
                // alert('search page');
                searchPage.handleChange(e.target.value);
            }
        }, 500)
    }


    componentDidMount() {
        console.log("====OverlayComponenet==");
        if (this.props.location) {
            const { fromNotifications } = this.props.location.state;

            console.log("=========Its me======");
            // console.log(fromNotifications);
        }
    }

    openUrl() {
        try {
            OpenTheUrlinWeb("https://api.whatsapp.com/send?phone=919100181181&text=" + encodeURIComponent("I need support with therapy for my kid/") + "%0a%0a")
        } catch (err) {
            console.log("Error in openUrl : " + err);
        }
    }

    render() {
        // var pageTyeHeader = window.location.href.split("http://localhost:5523/")[1];//location.href.split("https://nihn-sx2.conveyor.cloud/")[1];
        var pageTypeHeader = window.location.href.indexOf("prosuggestions") > -1 ? "suggestions" : (window.location.href.indexOf("search") > -1 || window.location.href.indexOf("/s/") > -1) ? "search" : window.location.href.indexOf("cart") > -1 ? "cart" : window.location.href.indexOf("completedorders") > -1 ? "completedorders" : window.location.href.indexOf("m/") > -1 ? "Details" : (window.location.href.split("//")[1].split("/")[1]);
        if (window.location.href.indexOf("/pros") > -1) {
            pageTypeHeader = "pros";
        }

        if (window.location.href.indexOf("/pd/") > -1) {
            pageTypeHeader = window.location.href.split("/pd/")[1];
        }
        else if (window.location.href.indexOf("/prosuggestions") > -1) {
            pageTypeHeader = "Suggestions";
        }
        else if (window.location.href.indexOf("/trumpet") > -1) {
            pageTypeHeader = "Guru";
        }
        else if (window.location.href.indexOf("/d/r") > -1) {
            pageTypeHeader = "Reports";
        }
        else if (window.location.href.indexOf("publishscreen") > -1
            || window.location.href.indexOf("createparent") > -1 || window.location.href.indexOf("createexpense") > -1 || window.location.href.indexOf("createservice") > -1
            || window.location.href.indexOf("createinvoice") > -1 || window.location.href.indexOf("createlead") > -1 || window.location.href.indexOf("createstaff") > -1
            || window.location.href.indexOf("touchbase") > -1 || window.location.href.toLowerCase().indexOf("bookedassessments") > -1 || window.location.href.indexOf("bookassessment") > -1 || window.location.href.indexOf("goalsetting") > -1 || window.location.href.indexOf("allmemos") > -1 || window.location.href.indexOf("allawards") > -1 || window.location.href.indexOf("mirraclesconfig") > -1 || window.location.href.indexOf("miraverse") > -1 || window.location.href.indexOf("mirraclesreport") > -1 || window.location.href.indexOf("mirraclesassigned") > -1   || window.location.href.toLowerCase().indexOf("pinnacleip") > -1 || window.location.href.toLowerCase().indexOf("goalskpi") > -1
       || window.location.href.toLowerCase().indexOf("microphoneguide") > -1) {
            pageTypeHeader = "Back";
        }



        var isOverlay = false,noscroll=false;
        if (window.location.href.toLowerCase().indexOf("hanuman") > -1 && window.location.href.toLowerCase().indexOf("goal") > -1) {
            pageTypeHeader = "View Goal";
        }
        if (window.location.href.toLowerCase().indexOf("payments") > -1 ) {
            pageTypeHeader = "Payments";
        }

        if (window.location.href.toLowerCase().indexOf("bossreceipts") > -1) {
            pageTypeHeader = "Receipts";
        }

        if (window.location.href.toLowerCase().indexOf("kpireport") > -1) {
            var parameterUrl = new URL(window.location.href);
            var hName = parameterUrl.searchParams.get("hname");
            pageTypeHeader = hName ? hName : "Back";
        }
        if (window.location.href.toLowerCase().indexOf("bankdetails") > -1) {
            pageTypeHeader = "Bank Details";
        }
        if (window.location.href.toLowerCase().indexOf("pfdetails") > -1) {
            pageTypeHeader = "PF Details";
        }
        if (window.location.href.toLowerCase().indexOf("cleaves") > -1) {
            pageTypeHeader = "Consecutive Leaves";
        }
        if (window.location.href.toLowerCase().indexOf("genericreports") > -1) {
            pageTypeHeader = "Generic Reports";
        }
        if (window.location.href.toLowerCase().indexOf("allcompensations") > -1) {
            pageTypeHeader = "All Compensations";
        }
        if (window.location.href.toLowerCase().indexOf("allparentleaves") > -1 || window.location.href.toLowerCase().indexOf("alltherapistleaves") > -1 ) {
            pageTypeHeader = "All Leaves";
        }
        if (window.location.href.toLowerCase().indexOf("recents") > -1) {
            pageTypeHeader = "Recents";
        }
        if (window.location.href.toLowerCase().indexOf("allcertificates") > -1) {
            pageTypeHeader = "Certificates";
        }
        
        if (window.location.href.toLowerCase().indexOf("allservicerequests") > -1) {
            pageTypeHeader = "Service Requests";
        }
        if (window.location.href.toLowerCase().indexOf("servicerequestdetails") > -1) {
            pageTypeHeader = "Back";
        }
        if (window.location.href.toLowerCase().indexOf("leaveapprovals") > -1) {
            pageTypeHeader = "Leave Approvals";
        }
        if (window.location.href.toLowerCase().indexOf("bbp") > -1) {
            pageTypeHeader = "Black Box Performance";
        }
        if (window.location.href.toLowerCase().indexOf("autismgpt") > -1) {
            pageTypeHeader = "Autism GPT";
        }

        if (window.location.href.toLowerCase().indexOf("pendingsgpt") > -1) {
            pageTypeHeader = "Pendings";
        }

        if (window.location.href.toLowerCase().indexOf("gptcategory") > -1) {
            pageTypeHeader = "GPTCategory";
        }

        if (window.location.href.toLowerCase().indexOf("policiesgpt") > -1) {
            pageTypeHeader = "System";
        }
        

        
        
      
        if (window.location.href.toLowerCase().indexOf("roffers") > -1 ) {
            pageTypeHeader = "Offers";
        }
        
        
        if (window.location.href.toLowerCase().indexOf("basicdetails") > -1) {
            pageTypeHeader = "Basic Details";
        }
        

        if (window.location.href.indexOf("pinnacleip") > -1  || window.location.href.indexOf("mirraclesfilter") > -1 || window.location.href.indexOf("pinnacleidcard") > -1) {
            isOverlay = true;
        }
        var isTopNothRequired = false;
        if (window.location.href.indexOf("pinnacleip") > -1  || window.location.href.indexOf("mirraclesfilter") > -1 || window.location.href.indexOf("mirracles") > -1 || window.location.href.indexOf("pinnacleidcard") > -1) {
            isTopNothRequired = true;
        }



var isHeightAuto=false;
        if (window.location.href.indexOf("mirraclesfilter") > -1)
{
isHeightAuto=true;
}
        if (window.location.href.toLowerCase().indexOf("goalskpi") > -1) {
            noscroll = true;
        }
        pageTypeHeader = pageTypeHeader.indexOf("?") > -1 ? pageTypeHeader.split("?")[0] : pageTypeHeader;
        console.log(pageTypeHeader);
        //var searchPageType = this.props.pageType;
        // console.log(location.href);
        return <div className={"fix-overlay " + (isOverlay ? " onlyoverlay " : " ") + (noscroll ? " noscroll " : " ") + (isHeightAuto ? " heightauto " : " ") + (isTopNothRequired ? " topnotchnr " : " " ) + (headerClassName) + " " + (window.location.href.indexOf("/sanjeevani") > -1 ? "sanjeevani" : "")}>
            {
                isOverlay ? <div className="closeoverlay" onClick={this.back.bind(this)}></div> : ""
            }
            {
                
                // window.location.href.indexOf("/profile") == -1 ?
                (pageTypeHeader && pageTypeHeader.indexOf("forcelogoff") > -1) || isOverlay || window.location.href.indexOf("/autismgpt") > -1 || window.location.href.indexOf("/policiesgpt") > -1 || window.location.href.indexOf("/gptcategory") > -1   || window.location.href.indexOf("/pendingsgpt") > -1   || (window.location.href.indexOf("/mirracles") > -1 && window.location.href.indexOf("/mirraclesreport") <= -1 && window.location.href.indexOf("/mirraclesconfig") <= -1 && window.location.href.indexOf("/mirraclesassigned") <= -1)   ? "" :
                    <div className="overlay-header">

                        {

                            <span className="back" onClick={this.back.bind(this)}>
                                {//    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="32px" height="32px" viewBox="0 0 32 32"><g transform="translate(0, 0)"><path d="M30.5,14H8.207l6.147-6.146a.5.5,0,0,0,0-.708l-2-2a.5.5,0,0,0-.708,0l-10.5,10.5a.5.5,0,0,0,0,.708l10.5,10.5a.5.5,0,0,0,.708,0l2-2a.5.5,0,0,0,0-.708L8.207,18H30.5a.5.5,0,0,0,.5-.5v-3A.5.5,0,0,0,30.5,14Z" fill="#000000"></path></g></svg>
                                }
                                <svg style={{ width: "25px" }} xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="32px" height="32px" viewBox="0 0 32 32" strokeWidth="2"><g strokeWidth="2" transform="translate(0, 0)"><line data-cap="butt" data-color="color-2" fill="none" stroke="#000000" strokeWidth="2" strokeMiterlimit="10" x1="30" y1="16" x2="2" y2="16" strokeLinejoin="miter" strokeLinecap="butt"></line> <polyline fill="none" stroke="#000000" strokeWidth="2" strokeLinecap="square" strokeMiterlimit="10" points="11,25 2,16 11,7 " strokeLinejoin="miter"></polyline></g></svg>

                            </span>
                        }

                        <div className="header-block">
                            {(pageTypeHeader && pageTypeHeader.indexOf("search") > -1) || pageTypeHeader == "package" || pageTypeHeader == "profiles" || pageTypeHeader == "parameters" || pageTypeHeader == "consultations" || pageTypeHeader == "assessments" || (pageTypeHeader == "consultations") ?
                                <div id="top-header-title" className="header-block-title header-block-title searchheader">
                                    Search
                                {

                                    }
                                </div>
                                : pageTypeHeader && pageTypeHeader == "o" ? <div id="top-header-title" className="header-block-title">Track Order</div>
                                    : pageTypeHeader && pageTypeHeader.indexOf("updates") > -1 ? <div id="top-header-title" className="header-block-title">Read More</div>
                                        : pageTypeHeader && pageTypeHeader.indexOf("hanuman") > -1 ? <div id="top-header-title" className="header-block-title">
                                            Hanuman
                                    </div> :
                                            pageTypeHeader && pageTypeHeader.indexOf("faq") > -1 ? <div id="top-header-title" className="header-block-title">
                                                Pinnacle FAQ
                                              
                                    </div> : pageTypeHeader && pageTypeHeader == "pros" ? <div id="top-header-title" className="header-block-title">
                                                Search
                                    </div> : pageTypeHeader && pageTypeHeader.indexOf("profile") > -1 ? <div id="top-header-title" className="header-block-title">
                                                Profile
                                                  </div> : pageTypeHeader && pageTypeHeader.indexOf("about") > -1 ? <div id="top-header-title" className="header-block-title">
                                                AboutUs
                                    </div> : pageTypeHeader && pageTypeHeader.indexOf("contactus") > -1 ? <div id="top-header-title" className="header-block-title">
                                                Contact Us
                                    </div> : pageTypeHeader && pageTypeHeader.indexOf("media-coverage") > -1 ? <div id="top-header-title" className="header-block-title">
                                                Media Love
                                    </div> : pageTypeHeader && pageTypeHeader.indexOf("privacypolicy") > -1 ? <div id="top-header-title" className="header-block-title">
                                                Privacy Policy
                                    </div> : pageTypeHeader && pageTypeHeader.indexOf("termsofuse") > -1 ? <div id="top-header-title" className="header-block-title">
                                                Terms of Use
                                    </div> : pageTypeHeader && pageTypeHeader.indexOf("cookiepolicy") > -1 ? <div id="top-header-title" className="header-block-title">
                                                Cookie Policy
                                    </div> :
                                                pageTypeHeader && pageTypeHeader.indexOf("changemobilenumber") > -1 ? <div id="top-header-title" className="header-block-title">
                                                    Change Mobile Number
                                    </div> : pageTypeHeader && pageTypeHeader.indexOf("attendancekpi") > -1 ? <div id="top-header-title" className="header-block-title">
                                                    Attendance KPI
                                    </div> : pageTypeHeader && pageTypeHeader.indexOf("serviceskpi") > -1 ? <div id="top-header-title" className="header-block-title">
                                                    Services KPI
                                    </div> : pageTypeHeader && pageTypeHeader.indexOf("goalskpi") > -1 ? <div id="top-header-title" className="header-block-title">
                                                    Goals KPI
                                    </div> : pageTypeHeader && pageTypeHeader.indexOf("assessmentskpi") > -1 ? <div id="top-header-title" className="header-block-title">
                                                    Assessnents KPI
                                    </div> : pageTypeHeader && pageTypeHeader.indexOf("usercard") > -1 ? <div id="top-header-title" className="header-block-title">
                                                    Profile
                                    </div> : pageTypeHeader && pageTypeHeader.indexOf("hermes") > -1 ? <div id="top-header-title" className="header-block-title">
                                                    Hermes
                                    </div> : pageTypeHeader && pageTypeHeader.indexOf("createinvoice") > -1 ? <div id="top-header-title" className="header-block-title">
                                                    Create Invoice
                                     </div> : pageTypeHeader && pageTypeHeader.indexOf("serviceland") > -1 ? <div id="top-header-title" className="header-block-title">
                                                    Service Land
                                    </div> : pageTypeHeader && pageTypeHeader.indexOf("allawards") > -1 ? <div id="top-header-title" className="header-block-title">
                                           All Awards
                                    </div> : pageTypeHeader && pageTypeHeader.indexOf("mirraclesassigned") > -1 ? <div id="top-header-title" className="header-block-title">
                                                                                                                    Mirracles Config
                                    </div> : pageTypeHeader && pageTypeHeader.indexOf("mirraclesconfig") > -1 ? <div id="top-header-title" className="header-block-title">
                                                                                                                            Mirracles Config
                                    </div> : pageTypeHeader && pageTypeHeader.indexOf("mirraclesreport") > -1 ? <div id="top-header-title" className="header-block-title">
                                                                                                                                Mirracles Report
                                    </div>
                                         : pageTypeHeader && pageTypeHeader.indexOf("miraverse") > -1 ? <div id="top-header-title" className="header-block-title">
                                                                                                                                       MiraVerse
                                    </div>
                                                                                                                            : pageTypeHeader && pageTypeHeader.indexOf("nuture") > -1 ? <div id="top-header-title" className="header-block-title">
                                                                                                                            Seeta <span id="seeta-total-counts-matchedrecords"></span>
                                    </div> : pageTypeHeader && pageTypeHeader.indexOf("forcelogoff") > -1 ? <div id="top-header-title" className="header-block-title">
                                                </div> : <div id="top-header-title" className="header-block-title">
                                                    {pageTypeHeader} <span id="total-counts-matchedrecords"></span>
                                                </div>
                            }

                            {pageTypeHeader && pageTypeHeader.indexOf("blackbox") > -1 ?
                                <div className="header-contact overlay-top-bar" style={{ display: "inline-block" }}>

                                    <div className="right">
                                        <span className="filter" ng-click="EnableBlackBoxFilter()" role="button" tabindex="0">
                                            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="32px" height="32px" viewBox="0 0 32 32" strokeWidth="1"><g strokeWidth="1" transform="translate(0.5, 0.5)"><polyline data-cap="butt" data-color="color-2" fill="none" stroke="#444444" strokeWidth="1" strokeMiterlimit="10" points="30.498,7.03 19,20 19,30 13,30 13,20 1.502,7.03 " strokeLinejoin="miter" strokeLinecap="butt"></polyline> <ellipse fill="none" stroke="#444444" strokeWidth="1" strokeLinecap="square" strokeMiterlimit="10" cx="16" cy="6" rx="15" ry="4" strokeLinejoin="miter"></ellipse></g></svg>
                                        </span>
                                        <span className="upload" ng-click="SyncBlackBoxToServer()" role="button" tabindex="0">
                                            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="32px" height="32px" viewBox="0 0 32 32" strokeWidth="1"><g strokeWidth="1" transform="translate(0.5, 0.5)"><line data-cap="butt" data-color="color-2" x1="16" y1="24" x2="16" y2="2" fill="none" stroke="#444444" strokeMiterlimit="10" strokeWidth="1" strokeLinecap="butt" strokeLinejoin="miter"></line> <polyline data-color="color-2" points="24 10 16 2 8 10" fill="none" stroke="#444444" strokeLinecap="square" strokeMiterlimit="10" strokeWidth="1" strokeLinejoin="miter"></polyline> <polyline points="2 23 2 30 30 30 30 23" fill="none" stroke="#444444" strokeLinecap="square" strokeMiterlimit="10" strokeWidth="1" strokeLinejoin="miter"></polyline></g></svg>
                                        </span>
                                        <span className="delete" ng-click="ClearBlackBox()" role="button" tabindex="0">
                                            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="32px" height="32px" viewBox="0 0 32 32" strokeWidth="1"><g strokeWidth="1" transform="translate(0.5, 0.5)"><path d="M27,10V28a3,3,0,0,1-3,3H8a3,3,0,0,1-3-3V10" fill="none" stroke="#444444" strokeLinecap="square" strokeMiterlimit="10" strokeWidth="1" strokeLinejoin="miter"></path> <line x1="16" y1="15" x2="16" y2="25" fill="none" stroke="#444444" strokeLinecap="square" strokeMiterlimit="10" strokeWidth="1" strokeLinejoin="miter"></line> <line x1="11" y1="15" x2="11" y2="25" fill="none" stroke="#444444" strokeLinecap="square" strokeMiterlimit="10" strokeWidth="1" strokeLinejoin="miter"></line> <line x1="21" y1="15" x2="21" y2="25" fill="none" stroke="#444444" strokeLinecap="square" strokeMiterlimit="10" strokeWidth="1" strokeLinejoin="miter"></line> <polyline data-cap="butt" data-color="color-2" points="11 6 11 1 21 1 21 6" fill="none" stroke="#444444" strokeMiterlimit="10" strokeWidth="1" strokeLinecap="butt" strokeLinejoin="miter"></polyline> <line data-color="color-2" x1="30" y1="6" x2="2" y2="6" fill="none" stroke="#444444" strokeLinecap="square" strokeMiterlimit="10" strokeWidth="1" strokeLinejoin="miter"></line></g></svg>
                                        </span>
                                    </div>
                                </div> :
                                pageTypeHeader && (pageTypeHeader.indexOf("faq") > -1 || window.location.href.toLowerCase().indexOf("hanuman") > -1) ? <div style ={{ width: "30%" ,marginTop:"-2px"}}>
                                    <span style={{ marginRight: "10px", float: "right" }} onClick={this.openUrl.bind(this)}>
                                        <svg style={{ width: "35px", paddingBottom: "10px" }} xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="48px" height="48px" viewBox="0 0 48 48"><g transform="translate(0, 0)"><path fill="#3AC34C" d="M0.10739,48l3.37542-12.32542c-2.08204-3.60688-3.17763-7.69894-3.17592-11.89098 C0.31215,10.66928,10.98554,0,24.09976,0c6.36475,0.00253,12.33865,2.47971,16.83067,6.97547 c4.49214,4.49575,6.96465,10.47174,6.96218,16.82712c-0.00531,13.11432-10.67966,23.785-23.79253,23.785h-0.00006h-0.00974 c-3.98213-0.00164-7.89481-1.00024-11.36995-2.89505L0.10739,48z"></path> <path fillRule="evenodd" clipRule="evenodd" fill="#FFFFFF" d="M18.15336,13.84087 c-0.48253-1.1589-0.97266-1.0021-1.33759-1.02027c-0.34636-0.01724-0.74309-0.02088-1.13941-0.02088 s-1.04036,0.14873-1.5853,0.74366c-0.54492,0.59496-2.08067,2.03281-2.08067,4.958c0,2.92519,2.13022,5.7512,2.42748,6.14787 c0.29723,0.39667,4.19204,6.39961,10.15569,8.97392c1.41836,0.6123,2.52578,0.9779,3.38909,1.25194 c1.42423,0.45222,2.72022,0.38844,3.74456,0.23544c1.14216-0.17059,3.51729-1.43777,4.01272-2.82601 c0.49537-1.38824,0.49537-2.57817,0.34674-2.82608c-0.14856-0.24784-0.54491-0.3966-1.13937-0.69412 c-0.59452-0.29745-3.51734-1.73529-4.06233-1.93359c-0.54491-0.19831-0.9412-0.29746-1.33755,0.29751 c-0.39628,0.59491-1.5356,1.9336-1.88241,2.3302c-0.34674,0.39667-0.69355,0.44627-1.28801,0.14876 c-0.59453-0.29745-2.51008-0.92506-4.7808-2.94978c-1.76725-1.57578-2.96047-3.52215-3.30725-4.11712 c-0.34677-0.59496-0.03689-0.91665,0.26073-1.2129c0.26739-0.26626,0.59446-0.69412,0.89172-1.04118 c0.29723-0.34706,0.39632-0.59497,0.59446-0.99157c0.19818-0.39667,0.09909-0.74372-0.04955-1.04121 C19.83772,17.956,18.64879,15.03077,18.15336,13.84087z"></path></g></svg>
                                    </span>
                                </div> :
                                <div className={"Header-rightoptions view-cart-header  checkout-cart-anmination " + ((pageTypeHeader && pageTypeHeader.indexOf("search") > -1) || pageTypeHeader == "package" || pageTypeHeader == "profiles" || pageTypeHeader == "parameters" || pageTypeHeader == "consultations" || pageTypeHeader == "assessments" || (pageTypeHeader == "consultations") ? "search-header" : " ")}>
                                    {pageTypeHeader && pageTypeHeader.toLowerCase() == "privacypolicy" || pageTypeHeader.toLowerCase() == "termsofuse" ? "" :
                                        ""
                                    }
                                </div>
                            }

                        </div>

                    </div>

            }


            <ErrorBoundary>
                <div className="overlay-content" style={{ paddingTop: (window.location.href.indexOf("/mirracles") > -1 && window.location.href.indexOf("/mirraclesconfig") <= -1) || window.location.href.indexOf("/gptcategory") > -1 || window.location.href.indexOf("/policiesgpt") > -1 || window.location.href.indexOf("/autismgpt") > -1  || window.location.href.indexOf("/pendingsgpt") > -1? "0px" : pageTypeHeader && pageTypeHeader.indexOf("forcelogoff") > -1 ? "20px" : "60px" }}>

                {
                    //<div id="overlay-container-div" className="" style={{ height: "calc( 100vh - 70px )", overflow: "hidden", width: "100%", overflowY: "auto", paddingBottom: "190px" }}95vh - 20px>
                }
                    <div id="overlay-container-div" className={(window.location.href.indexOf("/mirracles") > -1 && window.location.href.indexOf("/mirraclesassigned") <= -1 && window.location.href.indexOf("/mirraclesreport") <= -1 && window.location.href.indexOf("/mirraclesconfig") <= -1) ? "noscroll" :""} style={{ height: "calc( 100vh )", overflow: "hidden", width: "100%", overflowY: "auto" }}>
                   

                        <Route path={["/autismgpt"]}>
                            <Suspense fallback={<LoadingPlaceHolder Height="800px" />}>
                                <AutismGPT />
                            </Suspense>
                        </Route>

                     
                        
                </div>

                </div>
            </ErrorBoundary>
        </div>

    }
}

