import React from 'react';
import parse from 'html-react-parser';

export default class RLoadingPlaceHolder extends React.Component {
    //alert("Second Test Component Mounted");
    //<div class="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
    //<div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
    render() {
       
        var heightV = this.props.Height ? this.props.Height : "700px";
        var imagewidth = this.props.imagewidth ? this.props.imagewidth : "150px";
        var marginTop = this.props.mt ? this.props.mt : "260px";
        var fmt = this.props.fmt ? this.props.fmt : "26px";
        var fs = this.props.fs ? this.props.fs : "18px";
        return <div className="plh-loader" style={{ height: heightV, width: "100%", display: "inline-block", textAlign: "center", paddingTop: "0px", marginTop: marginTop }}>
            {this.props.IsAmblum ?  <img src="https://www.pinnacleblooms.org/images/logos/pinnacle-logo-emblem-200.png" className="rotate" style={{ display: "block", margin: "0px auto", width: imagewidth }} /> : this.props.IsAmblumWhite ? <img src="https://www.pinnacleblooms.org/images/pinnacle-amblum-white.png" className="rotate" style={{ display: "block", margin: "0px auto", width: imagewidth }} /> :  <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="48px" height="48px" viewBox="0 0 48 48" strokeWidth={2}><g strokeWidth={2} transform="translate(0, 0)"><g className="nc-loop-circle-48-icon-o" strokeWidth={2}><circle cx={24} cy={24} fill="none" opacity=".4" r={22} stroke="#444444" strokeLinecap="square" strokeWidth={2} strokeLinejoin="miter" /><path d="M24 2a22 22 0 0 1 22 22" data-cap="butt" data-color="color-2" fill="none" stroke="#444444" strokeWidth={2} strokeLinecap="butt" strokeLinejoin="miter" /></g><style strokeWidth={2} dangerouslySetInnerHTML={{__html: ".nc-loop-circle-48-icon-o{--animation-duration:0.5s;transform-origin:24px 24px;animation:nc-loop-circle-anim var(--animation-duration) infinite linear}@keyframes nc-loop-circle-anim{0%{transform:rotate(0)}100%{transform:rotate(360deg)}}" }} /></g></svg>}
            <span style={{ marginTop: fmt, display: 'inline-block', fontSize: fs, color: "#333", padding: "0px 20px",width:"100%",display:"inline-block"}}>{this.props.Message  ?  <>Pinnacle is loading first time for you.<br /> This could take few minutes.</> : this.props.LoadingText ? parse(this.props.LoadingText)  :  "loading..." } </span>
        </div>;
    }
}