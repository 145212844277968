
import React, { Component } from 'react';
import $ from 'jquery';
import { SetCurrentComponentName,SaveExceptionToSentry,GetCurrentComponentName } from "./RUtility";
export default class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        try {
            SetCurrentComponentName("ErrorBoundary");
          }
          catch {
          }
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }


    setComponentName(componentName) {
        // Set the component name in state
        this.setState({ componentName });


    }

    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service
        console.log(error);
        const { filename } = this.props;
        var exception = new Object();
        exception.Type = "Client Component Error";

        var userData = null;


        try {
            var user = localStorage.getItem("UserData");
            if (user) {
                userData = JSON.parse(user);

            }
        } catch (e) {

        }

       var components = "";
        try{
            const childComponents = React.Children.toArray(this.props.children);

            // Access the child components
            childComponents.forEach((child, index) => {
                const fileName = child.type.fileName;
                components+= fileName+" {#} ";
              
              //console.log(`Child component ${index + 1}:`, child);
            });
        }
        catch{

        }
        
      var name=GetCurrentComponentName();
        

        exception.Message =(name ? name : '')+ " "+  (userData ? userData.Handle : "") + " " +components+" -- " + error + JSON.stringify(errorInfo);
        SaveExceptionToSentry(exception, "Client Component Error", "", components + " -- " +  JSON.stringify(errorInfo) + error)
        console.log(errorInfo);
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return <div style={{ width: '100%', padding: '22px', paddingTop: '20px', height: 'calc( 100vh - 100px )', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <div>
                    <img src="/images/error.png" /><h1 style={{ fontSize: '19px', fontWeight: 100, textAlign: 'center', fontFamily: '"Muli"', lineHeight: '1.5' }}>Oh!! Something went wrong, Please do reload the Pinnacle<br /><br />If you still facing this issue, Please reach us <a href="tel:9100181181">9100 181 181</a>
                    </h1></div></div>


           
        }

        return this.props.children;
    }
}